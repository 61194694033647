import { BaseModel } from './base.model';
import { JsonObject } from '../helpers';

type PortalConfig = {
  enabled: boolean;
};

type PublicServiceConfig = {
  blockAction: 'redirect-portal' | 'block' | 'redirect-url';
  redirectUrl: string;
};

export class PolicyModel extends BaseModel {
  portal: PortalConfig;
  publicServices: PublicServiceConfig;

  constructor(props: JsonObject = {}) {
    super(props);

    const data = props as Record<keyof PolicyModel, unknown>;

    this.portal = (data.portal as PortalConfig) || { enabled: false };

    this.publicServices = (data.publicServices as PublicServiceConfig) || {
      blockAction: 'block',
      redirectUrl: '',
    };
  }
}
