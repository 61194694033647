import {
  format as dateFnsFormat,
  fromUnixTime,
  getUnixTime,
  getTime,
  isToday,
  isValid,
  parseISO,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export type NiceDate = {
  date: string;
  time: string;
  timeFull: string;
  formatted: string;
  dateObj: Date | null;
  timezone: string;
  offset: string;
};

export type DateVal = string | Date | number | null;

export enum DateFormat {
  MEDIUM = 'yyyy-MM-dd HH:mm',
  FULL = 'yyyy-MM-dd HH:mm:ss',
  FINE = 'yyyy-MM-dd HH:mm:ss.SSS',
}

export const getDateObj = (val: DateVal): Date | null => {
  if (!val) {
    return null;
  }

  if (val instanceof Date) {
    return val;
  }

  let dateObj: Date | null = null;

  if (String(val).match(/^[0-9]+$/) || typeof val === 'number') {
    const valSeconds = String(val).length === 13 ? Math.floor(Number(val) / 1000) : Number(val); // convert ms timestamps to s timestamps
    dateObj = fromUnixTime(valSeconds);
  } else {
    dateObj = parseISO(val);
  }

  if (isValid(dateObj)) {
    return dateObj;
  }

  return null;
};

export const getTimestamp = (val: DateVal, withMs?: boolean): number | null => {
  const dateObj = getDateObj(val);
  if (!dateObj) {
    return null;
  }

  return withMs ? getTime(dateObj) : getUnixTime(dateObj);
};

export const getNiceDate = (val: DateVal, format?: DateFormat, timezone?: string): NiceDate => {
  const niceDate: NiceDate = {
    date: '',
    time: '',
    timeFull: '',
    formatted: '',
    dateObj: null,
    timezone: '',
    offset: '',
  };
  let dateObj = getDateObj(val);

  if (!dateObj) {
    return niceDate;
  }

  if (timezone) {
    dateObj = getTimezoneDateObj(dateObj);

    if (!dateObj) {
      return niceDate;
    }
  }

  niceDate.dateObj = dateObj;
  niceDate.date = dateFnsFormat(dateObj, 'yyyy-MM-dd');
  niceDate.time = dateFnsFormat(dateObj, 'HH:mm');
  niceDate.timeFull = dateFnsFormat(dateObj, 'HH:mm:ss');
  niceDate.formatted = dateFnsFormat(dateObj, format || DateFormat.MEDIUM);
  niceDate.timezone = timezone || '';
  niceDate.offset = timezone ? dateFnsFormat(dateObj, 'XXX') : '';

  return niceDate;
};

export const getTimezoneDateObj = (val: DateVal, timezone?: string): Date | null => {
  const dateObj = getDateObj(val);

  if (!dateObj) {
    return null;
  }

  const targetTimezone = timezone || getUserTimezone().timezone;

  return utcToZonedTime(dateObj, targetTimezone) || null;
};

export const getUserTimezone = (): { timezone: string; offset: string } => {
  try {
    return {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      offset: dateFnsFormat(new Date(), 'XXX'),
    };
  } catch {
    return {
      timezone: '',
      offset: '',
    };
  }
};

export const getNiceRecentDate = (val: DateVal): string => {
  const dateObj = getDateObj(val);

  if (!dateObj) {
    return '';
  }

  if (isToday(dateObj)) {
    return dateFnsFormat(dateObj, 'h:mmaaa');
  }

  return dateFnsFormat(dateObj, 'LLL do @ h:mmaaa');
};
