import { get } from './sp-api.service';
import { PolicyModel } from '../models/policy.model';
import { JsonObject } from '../helpers';

export const isPortalEnabled = async (): Promise<boolean> => {
  const userPolicy = await getUserPolicy();

  if (!userPolicy) {
    return false;
  }

  return !!userPolicy.portal?.enabled;
};

export const getUserPolicy = async (): Promise<PolicyModel | null> => {
  const response = await get(`/policy/portal`);
  return response ? new PolicyModel(response as JsonObject) : null;
};
