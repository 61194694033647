import { Dataset, JsonObject } from '../helpers';
import { ApiResponse } from './index';
import { AuthProvider } from './auth.service';
import axios, { AxiosResponse } from 'axios';
import Environment from '../environment';
import { getTimestamp } from '../date-helpers';
import { ShortcutMessage } from '../models/shortcut.model';
import { cloneDeep } from 'lodash';
import { get as spApiGet, post as spApiPost } from './sp-api.service';
import { ThreadModel } from '../models/thread/thread.model';
import { ThreadMessageModel } from '../models/thread/thread-message.model';
import { ThreadRole } from '../models/thread';

const DEFAULT_PAGE_SIZE = 100;

const axiosClient = axios.create({
  baseURL: Environment.SP_EDGE_API_URL,
  timeout: 120 * 1000,
  responseType: 'json',
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

axiosClient.interceptors.request.use((config) => {
  if (AuthProvider.token) {
    config.headers['Surepath-Authorization'] = `Bearer ${AuthProvider.token}`;
  }
  return config;
});

const post = async (path: string, data: JsonObject): Promise<ApiResponse> => {
  return axiosClient.post(path, data).then(handleResponse);
};

const handleResponse = (response: AxiosResponse | null): ApiResponse => {
  if (!response?.data) {
    return null;
  }

  if (Array.isArray(response?.data)) {
    return response?.data as JsonObject[];
  }

  return response?.data as JsonObject;
};

export const getThreadById = async (threadId: string): Promise<ThreadModel | null> => {
  const response = (await spApiGet(`/user/conversation/${threadId}`).catch(
    () => null
  )) as JsonObject | null;

  return response ? new ThreadModel(response) : null;
};

export const getAllThreads = async (keyword?: string): Promise<ThreadModel[]> =>
  (await getThreads(0, 1000, keyword)).rows;

export const getThreads = async (
  page = 0,
  pageSize = DEFAULT_PAGE_SIZE,
  keyword?: string
): Promise<Dataset<ThreadModel>> => {
  const payload = keyword ? { match: { keyword } } : {};
  const response = (await spApiPost('/user/conversation', payload)) as JsonObject[];

  if (!response?.length) {
    return {
      page,
      pageSize,
      rows: [],
      total: 0,
    };
  }

  // @todo user service should just do this
  response.forEach((data, index) => {
    if (data.latestUserMessage && !(data.conversation as JsonObject[])?.length) {
      response[index].conversation = [cloneDeep(data.latestUserMessage)];
      delete response[index].lastUserMessage;
    }
  });

  return {
    page,
    pageSize,
    rows: response.map((data) => new ThreadModel(data)),
    total: response.length,
  };
};

export const sendUserMessage = async (
  message: ThreadMessageModel
): Promise<ThreadMessageModel[] | null> => {
  const { content, shortcut, threadId, datasourceIds, privateModelId, agentId } = message;
  const isDefaultAgent = !agentId;

  try {
    const response = (await post('/message', {
      messages: [{ role: 'user', content, shortcut, datasourceIds }],
      conversationId: threadId,
      assistantId: agentId,
      privateModelId: isDefaultAgent ? privateModelId : '',
    })) as {
      conversationId: string;
      messages: { role: string; content: string }[];
    };

    if (!Array.isArray(response?.messages)) {
      return null;
    }

    return response.messages.map(({ content, role }) => {
      const systemMessage = new ThreadMessageModel({
        role,
        content,
        timestamp: getTimestamp(new Date(), true) || 0,
        violations: {
          sensitiveData: false,
          intent: false,
          access: false,
          input: false,
          output: false,
        },
        agentId,
        privateModelId,
      });

      return systemMessage;
    });
  } catch (err) {
    return null;
  }
};

/*
 * Create a message that has not yet been sent server-side and turned into a user event. The message can be consumed
 * as expected by the UI, and should be updated to reflect correct id and other information, once the server responds
 */
export const makeDeckMessage = (
  threadId: string,
  role: ThreadRole,
  content: string,
  shortcut?: ShortcutMessage
): ThreadMessageModel => {
  return new ThreadMessageModel({
    timestamp: getTimestamp(new Date(), true),
    threadId,
    shortcut,
    role,
    content,
    onDeck: true,
  });
};

export const makeDeckFileMessage = (
  threadId: string,
  filename: string,
  summary = ''
): ThreadMessageModel => {
  const fileMessage = makeDeckMessage(threadId, 'user', '', undefined);

  fileMessage.eventRole = 'context';

  fileMessage.eventContext = {
    name: filename,
    origin: 'user',
    type: 'file',
    summary,
    source: '',
  };

  return fileMessage;
};
