import { getUUID, JsonObject } from '../../helpers';
import { orderBy } from 'lodash';
import { ThreadMessageModel } from './thread-message.model';
import { ThreadContext } from '.';

export class ThreadModel {
  id: string;
  orgId: string;
  userId: string;
  traceId: string;
  requestId: string;
  timestamp: number; // this is the most-recent message timestamp
  conversation: ThreadMessageModel[];
  context: ThreadContext = new Map();

  constructor(props: JsonObject = {}) {
    const data = props as Record<keyof ThreadModel | 'conversationId', unknown>;

    this.id = (data.conversationId as string) || (data.id as string) || getUUID();
    this.orgId = (data.orgId as string) || '';
    this.userId = (data.userId as string) || '';
    this.traceId = (data.traceId as string) || '';
    this.requestId = (data.requestId as string) || '';
    this.timestamp = (data.timestamp as number) || 0;
    this.conversation = [];

    // some context messages are not display in the thread, and the look-up is via message index, so
    // we must iterate through messages and pick them out
    ((data.conversation as JsonObject[]) || []).forEach((data, index) => {
      data.threadId = this.id;
      const message = new ThreadMessageModel(data);

      if (message.context) {
        this.context.set(index, message.context);

        // user file uploads are displayed as user messages inline in the thread
        if (message.context.type !== 'file') {
          return;
        }
      }

      this.conversation.push(message);
    });
  }

  get latestUserMessage(): ThreadMessageModel | null {
    if (!this.conversation.length) {
      return null;
    }

    return orderBy(
      this.conversation.filter(({ role }) => role === 'user'),
      ['timestamp'],
      ['desc']
    )[0];
  }

  get latestSystemMessage(): ThreadMessageModel | null {
    if (!this.conversation.length) {
      return null;
    }

    return orderBy(
      this.conversation.filter(({ role }) => role === 'system'),
      ['timestamp'],
      ['desc']
    )[0];
  }

  // get most recently used private model, so we can set thread settings when loading a thread
  get latestPrivateModelId(): string {
    return this.latestSystemMessage?.privateModelId || '';
  }

  // same as private model above
  get latestAgentId(): string {
    return this.latestUserMessage?.agentId || '';
  }

  // A thread is locked if any message in the thread contains an intent violation
  get locked(): boolean {
    return this.conversation.some(({ locked }) => locked);
  }
}
