import { ClassAttributes, CSSProperties, FC, HTMLAttributes, LegacyRef } from 'react';
import Markdown, { ExtraProps } from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize';
import remarkBreaks from 'remark-breaks';
import htmlEntities from 'he';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Box, Stack, Theme } from '@mui/material';
import copy from 'copy-to-clipboard';
import DoneIcon from '@/components/icon/done-icon';
import { createUseStyles } from 'react-jss';
import { cloneDeep, remove } from 'lodash';
import Text from '@/components/text';
import { ThreadMessageModel } from '@/lib/models/thread/thread-message.model';

interface Props {
  message: ThreadMessageModel;
}

const useStyles = createUseStyles((theme: Theme) => ({
  messageContent: {
    '& pre': {
      margin: 0,
    },
    overflowWrap: 'break-word',
    textWrap: 'wrap',
    '& li $paragraph': {
      marginBottom: 0,
    },
  },
  codeContainer: {
    padding: 0,
    margin: '20px 0px',
  },
  codeBlock: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    marginTop: '0px !important',
    padding: '16px !important',
  },
  paragraph: {
    marginBottom: 10,
  },
  fileCitation: {
    marginTop: 10,
  },
  header: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    backgroundColor: '#2d2d2d9c',
    color: theme.palette.common.white,
    padding: '4px 16px',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.body2,
    '& .copy-button': {
      lineHeight: '100%',
    },
  },
}));

const getRehypeSanitizeSchema = () => {
  const schema = cloneDeep(defaultSchema);
  const disallowedTags = ['img', 'input', 'picture', 'source'];
  schema.tagNames = remove(schema.tagNames!, (tag) => !disallowedTags.includes(tag));
  return schema;
};

const CodeBlock: FC = (
  props: ClassAttributes<HTMLElement> & HTMLAttributes<HTMLElement> & ExtraProps
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { children, className, ref, node, ...rest } = props;
  const [, language] = String(className).match(/language-(\w+)/) || [];
  const styles = useStyles();

  if (!language) {
    return (
      <code {...rest} className={className}>
        {children}
      </code>
    );
  }

  const codeContent = String(children).replace(/\n$/, '');

  const handleCopyCode = () => {
    copy(codeContent);
  };

  return (
    <Box className={styles.codeContainer}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        className={styles.header}
      >
        <Box>{String(language).toUpperCase()}</Box>
        <Box className="copy-button">
          <DoneIcon size="small" name="copy" onClick={handleCopyCode} />
        </Box>
      </Stack>
      <SyntaxHighlighter
        {...rest}
        ref={ref as LegacyRef<SyntaxHighlighter> | undefined}
        PreTag="div"
        children={codeContent}
        language={language}
        style={tomorrow as Record<string, CSSProperties>}
        className={styles.codeBlock}
      />
    </Box>
  );
};

const MessageContent: FC<Props> = ({ message }) => {
  const { content = '', component, role } = message;
  let safeContent = String(content).trim();
  const styles = useStyles();

  if (component) {
    return <Box className={styles.messageContent}>{component}</Box>;
  }

  if (!safeContent) {
    return (
      <Box className={styles.messageContent}>
        <Text italic>&lt;message not available&gt;</Text>
      </Box>
    );
  }

  // user input should not support markdown or html
  if (role === 'user') {
    safeContent = htmlEntities.escape(safeContent);
  }

  return (
    <Box className={styles.messageContent}>
      <Markdown
        children={safeContent}
        remarkPlugins={[remarkBreaks]}
        rehypePlugins={[rehypeRaw, [rehypeSanitize, getRehypeSanitizeSchema()]]}
        components={{
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          p: ({ node, ...props }) => {
            return (
              <div {...props} className={styles.paragraph}>
                {props.children}
              </div>
            );
          },
          code: (props) => <CodeBlock {...props} />,
        }}
      />
    </Box>
  );
};

export default MessageContent;
