import Text from '@/components/text';
import useThread from '@/hooks/use-thread.hook';
import useUserSettings from '@/hooks/use-user-settings.hook';
import { Box, Fade, Stack, Theme } from '@mui/material';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';
import AgentSelector from './agent-selector';
import Loader from '@/components/loader';
import OrgBadge from '@/components/org-badge';

const useStyles = createUseStyles((theme: Theme) => ({
  threadOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    backgroundColor: theme.palette.common.white,
  },
  settingsLoader: {
    width: '80%',
  },
}));

const ThreadOverlay: FC = () => {
  const styles = useStyles();
  const { thread, loadState } = useThread();
  const { agents, isLoading: settingsLoading } = useUserSettings();

  const hasMessages = !!thread.conversation.length;
  const threadLoading = loadState === 'loading' || settingsLoading;
  const hasAgents = !!agents.length;

  return (
    <>
      <Fade
        in={threadLoading}
        className={styles.threadOverlay}
        mountOnEnter
        unmountOnExit
        timeout={90}
      >
        <Stack width="100%" height="100%" alignItems="center" justifyContent="center">
          {settingsLoading && <Loader className={styles.settingsLoader} />}
        </Stack>
      </Fade>
      {!threadLoading && !hasMessages && (
        <Stack className={styles.threadOverlay} gap={3} alignItems="center" justifyContent="center">
          <Stack gap={2} alignItems="center" width="100%">
            {!hasAgents && (
              <>
                <OrgBadge />
                <Text>What would you like to get done today?</Text>
              </>
            )}
            {hasAgents && (
              <Box height="300px" width="40%" mb={2}>
                <AgentSelector />
              </Box>
            )}
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default ThreadOverlay;
