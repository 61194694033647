import Icon from '@/components/icon';
import Menu from '@/components/menu';
import Text from '@/components/text';
import Tooltip from '@/components/tooltip';
import useUserSettings from '@/hooks/use-user-settings.hook';
import { Box, Stack, Theme } from '@mui/material';
import { FC, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  datasourceIds: string[];
}

const useStyles = createUseStyles((theme: Theme) => ({
  datasourceMenu: {
    maxWidth: 500,
    backgroundColor: '#5b5b5b',
    color: theme.palette.common.white,
    '& .button:hover': {
      color: theme.palette.common.white,
      opacity: 0.9,
    },
  },
  footer: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    marginTop: 16,
    paddingTop: 16,
  },
}));

const MessageDatasourceMenu: FC<Props> = ({ datasourceIds }) => {
  const styles = useStyles();
  const { datasources } = useUserSettings();

  const TriggerEl = (
    <Tooltip title="Searched Data Sources" placement="bottom">
      <Icon name="manage-search" size="small" />
    </Tooltip>
  );

  return (
    <Menu
      trigger={TriggerEl}
      title="Searched Data Sources"
      anchorOrigin={{ vertical: 20, horizontal: 2 }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      className={styles.datasourceMenu}
    >
      <Box width="100%">
        <Stack gap={0.5} justifyContent="flex-start" maxWidth="100%">
          {datasourceIds.map((datasourceId, index) => {
            const { model: datasource } = datasources.get(datasourceId) || { model: null };
            let TitleEl: ReactNode = null;

            if (datasource) {
              TitleEl = (
                <Text>{datasource.isPublicInternet ? 'Public Internet' : datasource.name}</Text>
              );
            } else {
              // console.warn('unknown datasource', datasourceId);
              TitleEl = <Text italic>&lt;data source unavailable&gt;</Text>;
            }

            return (
              <Stack direction="row" gap={0.2} key={datasourceId}>
                <Text bold>{index + 1}.</Text>
                {TitleEl}
              </Stack>
            );
          })}
        </Stack>
      </Box>
    </Menu>
  );
};

export default MessageDatasourceMenu;
