import Text from '@/components/text';
import { ThreadFile as ThreadFileType } from '@/lib/models/thread';
import { Stack, Theme } from '@mui/material';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';
import FileIcon from './upload-form/file-icon';

interface Props {
  file: ThreadFileType;
}

const useStyles = createUseStyles((theme: Theme) => ({
  threadFile: {
    gap: 8,
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    padding: 8,
    alignItems: 'flex-start',
  },
}));

const ThreadFile: FC<Props> = ({ file }) => {
  const styles = useStyles();
  const { name, ext, summary } = file;
  const hasSummary = !!summary;

  return (
    <Stack direction="row" className={styles.threadFile}>
      <FileIcon extension={ext} />
      <Stack flexGrow={1} gap={2}>
        <Stack gap={0.5}>
          <Text size="small" bold>
            File Upload:
          </Text>
          <Text size="small">{name}</Text>
        </Stack>

        <Stack gap={0.5}>
          <Text size="small" bold>
            Summary:
          </Text>
          {hasSummary && <Text size="small">{summary}</Text>}
          {!hasSummary && (
            <Text italic size="small">
              not available
            </Text>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ThreadFile;
