import { JsonObject } from '../helpers';
import { AgentModel } from '../models/agent.model';
import { OrgBrandingModel } from '../models/org-branding.model';
import { ShortcutModel, ShortcutValue } from '../models/shortcut.model';
import { get } from './sp-api.service';

export const ORG_DEFAULT_AGENT_ID = 'org-default';

type GetShortcutResponse = {
  shortcuts: {
    global: JsonObject[];
    org: JsonObject[];
  };
};

export const getAvailableShortcuts = async (): Promise<ShortcutModel[]> => {
  const response = (await get('/shortcut/shortcut/portal')) as GetShortcutResponse;

  if (!response?.shortcuts?.global?.length) {
    return [];
  }

  return response.shortcuts.global.map((data) => new ShortcutModel(data));
};

export const getShortcutById = async (shortcutId: string): Promise<ShortcutModel | null> => {
  const response = (await get(`/shortcut/shortcut/${shortcutId}`)) as JsonObject;
  return response ? new ShortcutModel(response) : null;
};

export const isShortcutValueEmpty = (value: ShortcutValue): boolean => {
  const compValue = Array.isArray(value) ? value : [value];
  return compValue.every((val) => !String(val).trim());
};

export const getAvailableAgents = async (): Promise<AgentModel[]> => {
  const response = await get('/shortcut/assistant/portal');

  if (!response?.length) {
    return [];
  }

  return (response as JsonObject[]).map((data) => new AgentModel(data));
};

export const getDefaultOrgAgent = (branding: OrgBrandingModel): AgentModel => {
  const { badgeTitle, portalBadge } = branding;

  return new AgentModel({
    id: ORG_DEFAULT_AGENT_ID,
    name: badgeTitle,
    portal: {
      description: `The default assistant for ${branding.orgName || 'this organization'}`,
      badge: { image: portalBadge.image, title: badgeTitle },
    },
  });
};
