import { Stack } from '@mui/material';
import { FC, useMemo, useState } from 'react';
import TextInput from '../form/text-input';
import useThread from '@/hooks/use-thread.hook';
import { useQuery } from 'react-query';
import { getAvailableShortcuts } from '@/lib/services/shortcut.service';
import SidebarList from './sidebar-list';
import { QueryKey } from '@/lib/query-client';
import TabHeader from './tab-header';
import TabContent from './tab-content';

const ShortcutTab: FC = () => {
  const [keyword, setKeyword] = useState('');

  const { loadShortcut } = useThread();

  const handleKeywordChange = (value: string) => {
    setKeyword(value);
  };

  const { data = [], isLoading } = useQuery([QueryKey.ShortcutsList], async () => getAvailableShortcuts());

  const listItems = useMemo(() => {
    const safeKeyword = String(keyword).trim().toLowerCase();

    const filteredData = data.filter(
      ({ name, description }) =>
        String(name).toLowerCase().includes(safeKeyword) ||
        String(description).toLowerCase().includes(safeKeyword)
    );

    return filteredData.map((shortcut) => {
      const { id, name, description } = shortcut;

      return {
        key: id,
        title: name,
        content: description,
        onClick: () => loadShortcut(shortcut),
      };
    });
  }, [data, keyword, loadShortcut]);

  return (
    <Stack gap={2} width="100%" height="100%" maxHeight="100%">
      <TabHeader icon="shortcut" title="Shortcuts" />

      <TabContent>
        <TextInput
          name="shortcut-search"
          value={keyword}
          onChange={handleKeywordChange}
          size="small"
          startIcon="search"
          placeholder="Search shortcuts"
          clearable
        />
      </TabContent>

      <SidebarList isLoading={isLoading} items={listItems} />
    </Stack>
  );
};

export default ShortcutTab;
