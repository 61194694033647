import { Stack } from '@mui/material';
import { FC, useState } from 'react';
import Text from '../text';
import TextInput from '../form/text-input';
import SidebarList from './sidebar-list';
import TabHeader from './tab-header';
import TabContent from './tab-content';
import { getDefaultOrgAgent, ORG_DEFAULT_AGENT_ID } from '@/lib/services/shortcut.service';
import useUserSettings from '@/hooks/use-user-settings.hook';
import useApp from '@/hooks/use-app.hook';
import { AgentModel } from '@/lib/models/agent.model';
import useThread from '@/hooks/use-thread.hook';

const searchAgents = (agents: AgentModel[], keyword?: string) => {
  if (!agents.length) {
    return [];
  }

  const safeKeyword = String(keyword).trim().toLowerCase();

  if (!safeKeyword) {
    return agents;
  }

  const fAgents = safeKeyword
    ? agents.filter(({ portal }) => {
        return [portal.description, portal.badge.title].some((val) =>
          String(val).toLowerCase().includes(safeKeyword)
        );
      })
    : agents;

  return fAgents;
};

const AgentTab: FC = () => {
  const [keyword, setKeyword] = useState('');
  const { agents, isLoading } = useUserSettings();
  const { branding } = useApp();
  const { loadThread } = useThread();

  const handleKeywordChange = (value: string) => {
    setKeyword(value);
  };

  const handleLoadAgent = (agentId: string) => {
    loadThread(undefined, agentId === ORG_DEFAULT_AGENT_ID ? '' : agentId);
  };

  const fAgents = isLoading ? [] : searchAgents([getDefaultOrgAgent(branding), ...agents], keyword);

  const listItems = fAgents.map((agent) => {
    const {
      id,
      portal: { description, badge },
    } = agent;

    return {
      title: badge.title,
      content: description ? <Text size="small">{description}</Text> : null,
      key: `agent-${id}`,
      onClick: () => handleLoadAgent(id),
    };
  });

  return (
    <Stack gap={2} width="100%" height="100%" maxHeight="100%">
      <TabHeader icon="assistant" title="Assistants" />
      <TabContent>
        <TextInput
          name="assistant-search"
          value={keyword}
          onChange={handleKeywordChange}
          size="small"
          startIcon="search"
          placeholder="Search assistants"
          clearable
        />
      </TabContent>
      <SidebarList isLoading={isLoading} items={listItems} />
    </Stack>
  );
};

export default AgentTab;
